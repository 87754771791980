import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { Button } from "@opsdti-global-component-library/amgen-design-system";

import { TileConfig, getKeyEventsTileImage } from "api/updateHomepageImage";
import { HomeTile } from "./home-tile/HomeTile";

export const KeyEventsHomeTile = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery<TileConfig>(
    ["key-events-tile-image-url"],
    getKeyEventsTileImage
  );

  return (
    <HomeTile
      title="Key Events"
      imageUrl={data?.imageUrl}
      isLoading={isLoading}
      footer={
        <Button
          onClick={() => navigate("/prioritized-agenda/key-events/1")}
          text="Go To Forward 12 Quarters Page"
          type="secondary"
          block
        />
      }
    />
  );
};
